import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
// import dashInfo from "./states/dashInfo";
// import {
//   StateMachineProvider,
//   createStore,
//   useStateMachine,
// } from "little-state-machine";
// createStore({
//     dashInfo
// });

// const store = createStore(reducers, {}, applyMiddleware(reduxThunk));
// /*<Provider store={store}><App /></Provider>,*/

ReactDOM.render(
  // <StateMachineProvider>
  <App />,
  // </StateMachineProvider>,
  document.querySelector("#root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

import React, { useState, useEffect, useReducer, useRef } from "react";
import { Row, Col, Card, Spinner, Button, Input } from "reactstrap";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import axios from "axios";
// import dayjs from "dayjs";
// import utc from "dayjs/plugin/utc";
import { DateTime } from "luxon";
import LyricsModal from "./LyricsModal";
import { orderBy } from "lodash";
import LyricsEdit from "./LyricsEdit";
import useSWR, { useSWRConfig } from "swr";
// import { useStateMachine } from "little-state-machine";
// import { getDashInfo } from "../../actions/dashInfo";
const fetcher = (url) => fetch(url).then((res) => res.json());

function useUser(id) {
  const { data, error } = useSWR(`/api/lyrics`, fetcher);

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
  };
}

const Lyrics = (props) => {
  // const { state, actions } = useStateMachine({ getDashInfo });

  const [hasError, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lyrics, setLyrics] = useState([]);
  const [deleteActive, setDeleteActive] = useState(false);
  const [response, setResponse] = useState("");

  // const { data, error } = useSWR(`/api/lyrics`, fetcher);
  const { mutate } = useSWRConfig();

  const { data, isLoading, error } = useUser();

  if (error) return <div>failed to load</div>;
  if (isLoading)
    return (
      <Card className="rounded-more loading-card border text-center text-dark p-5">
        <Spinner className="mx-auto" color="secondary" />
      </Card>
    );

  // dayjs.extend(utc);

  // const getLyrics = async () => {
  //   try {
  //     const lyrics = await axios.get(`/api/lyrics`);
  //
  //     if (_isMounted.current) {
  //       setLyrics(lyrics);
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     console.log("Error in the getLyrics() async method: " + err);
  //   }
  // };

  // async function fetchData() {
  //   axios
  //     .get(`/api/lyrics`)
  //     .then((res) => {
  //       const data = res.data;
  //       if (_isMounted.current) {
  //         setLyrics(data);
  //         setLoading(false);
  //         // actions.getDashInfo({ currentName: "loooo" });
  //       }
  //     })
  //     .catch((err) => setErrors(err));
  // }
  //
  // useEffect(() => {
  //   fetchData();
  // }, [lyrics.length, loading]);

  const { showDelete, showDetails } = props;

  const handleDelete = async (id) => {
    console.log(id);

    const joe = await axios
      .delete(`/api/lyrics/${id}`)
      .then((res) => {
        // console.log("Response: " + JSON.stringify(res));
        setResponse(JSON.stringify(res));
        // fetchData();
        mutate(`/api/lyrics`);
        return res.data;
      })
      .catch((err) => setErrors(err));

    joe.success && setDeleteActive(false);
  };

  const mutateLyrics = () => {
    mutate(`/api/lyrics`);
  };

  return (
    <div className="rounded-more border text-center text-dark px-2 pt-4 pb-5 mb-4">
      {hasError && console.log(hasError)}
      {response && (
        <div
          className="border rounded-more p-2 m-4"
          style={{ overflow: "auto", maxHeight: "100px" }}
        >
          {response}
        </div>
      )}
      {/*{state && JSON.stringify(state.dashInfo.currentName)}*/}
      {/*{state && state.dashInfo.currentName}*/}
      <Row>
        <Col>
          <h4 className="mb-0">LYRICS</h4>
          <h6 className="mb-3">(select song to view lyrics)</h6>
          {!Array.isArray(data) || !data.length ? (
            <div>
              {data.length < 1 ? (
                "There are no lyrics in the database"
              ) : (
                <Card className="loading-card p-4">
                  <Spinner className="mx-auto" color="secondary" />
                </Card>
              )}
            </div>
          ) : (
            <div className="overflow-auto">
              {orderBy(data, ["releaseDate", "trackNumber"], ["desc", "asc"])
                // .reverse()
                .map(
                  ({
                    songTitle,
                    releaseDate,
                    albumTitle,
                    lyrics,
                    trackNumber,
                    _id,
                  }) => {
                    return (
                      <div
                        className={`d-inline-block my-0 mt-sm-1  ${
                          !!showDetails ? "border rounded-more m-2 p-2" : ""
                        }`}
                        key={_id}
                      >
                        {/*<Card body size="sm" className="px-1 pt-2 text-dark">*/}
                        <div className="p-2 text-dark">
                          {!!showDetails && (
                            <>
                              <h3>
                                "{songTitle}" ({trackNumber ? trackNumber : "-"}
                                )
                              </h3>
                              <div className="opacity-1 my-n2">
                                {albumTitle ? albumTitle : "-----"}
                              </div>
                              {releaseDate && (
                                <div>
                                  {/*{dayjs(releaseDate).utc().format("MM/DD/YYYY")}*/}
                                  {DateTime.fromISO(releaseDate, {
                                    zone: "UTC",
                                  }).toLocaleString()}
                                </div>
                              )}
                            </>
                          )}
                          <div>
                            {/*{parse(DOMPurify.sanitize(lyrics))}*/}
                            <LyricsModal
                              lyrics={lyrics}
                              songTitle={songTitle.toUpperCase()}
                              buttonLabel={songTitle}
                              className="text-center text-dark"
                            />
                          </div>

                          {!!showDelete && (
                            <div className="border rounded-more p-4 m-3 d-inline-block ">
                              <LyricsEdit
                                buttonLabel={"Edit"}
                                lyrics={lyrics}
                                songTitle={songTitle}
                                albumTitle={albumTitle}
                                trackNumber={trackNumber}
                                releaseDate={releaseDate}
                                id={_id}
                                //passing fetchData down stream 2 components to refresh this upon edit
                                fetchData={mutateLyrics}
                                classNameProp={"text-dark text-center"}
                              />
                              <div className="d-flex mt-4">
                                <Button
                                  // size="sm"
                                  onClick={() => handleDelete(_id)}
                                  color="danger"
                                  disabled={!deleteActive}
                                >
                                  Delete
                                </Button>
                                <Input
                                  // bsSize="sm"
                                  value={_id}
                                  onChange={() => setDeleteActive(true)}
                                />
                              </div>
                            </div>
                          )}

                          {/*<Link*/}
                          {/*  to={"edit/topic/" + _id}*/}
                          {/*  className="custom-link-hover nav-link text-dark"*/}
                          {/*>*/}
                          {/*  <div>*/}
                          {/*    {songTitle} {releaseDate && <sup>{releaseDate}</sup>}*/}
                          {/*  </div>*/}
                          {/*  <div className="opacity-1 my-n2">{albumTitle}</div>*/}
                          {/*  <div className="text-black-50">*/}
                          {/*      {parse(DOMPurify.sanitize(lyrics))}*/}
                          {/*  </div>*/}
                          {/*</Link>*/}
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Lyrics;

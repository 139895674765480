import React, { Component } from "react";
// import { connect } from "react-redux";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  Collapse,
  Nav,
  NavLink,
  NavItem,
  NavbarToggler,
  Container,
} from "reactstrap";

class Header extends Component {
  state = {
    isOpen: false,
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  // checkAdmin() {
  //   if (true) {
  //     return (
  //       <span>
  //         <NavItem>
  //           <NavLink tag={RRNavLink} to="/drugs">
  //             MEDITATE
  //           </NavLink>
  //         </NavItem>
  //         <NavItem>
  //           <NavLink tag={RRNavLink} to="/icd-codes">
  //             SPREAD LOVE
  //           </NavLink>
  //         </NavItem>
  //       </span>
  //     );
  //   }
  //   return;
  // }

  renderContent() {
    switch (true) {
      case null:
        return;
      case false:
        return (
          <Nav>
            <NavItem>
              <a href="/auth/google">Login With Google</a>
            </NavItem>
          </Nav>
        );
      default:
        return (
          <Nav className="mx-auto align-items-center" navbar>
            <NavItem>
              <NavLink tag={RRNavLink} to="/film">
                FILM
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RRNavLink} to="/music">
                MUSIC
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RRNavLink} to="/lyrics">
                LYRICS
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RRNavLink} to="/shop">
                SHOP
              </NavLink>
            </NavItem>

            {/*{this.checkAdmin()}*/}

            {/*LATER ADD A CHECK ADMIN BOOLEAN*/}
            <NavItem>
              <NavLink tag={RRNavLink} to="/meditate">
                MEDITATE
              </NavLink>
            </NavItem>
            {/*<NavItem>*/}
            {/*  <NavLink tag={RRNavLink} to="/spread-love">*/}
            {/*    KINDNESS*/}
            {/*  </NavLink>*/}
            {/*</NavItem>*/}

            {/*<NavItem>*/}
            {/*<a href="/api/logout">Logout {this.props.auth.name}</a>*/}
            {/*</NavItem>*/}
          </Nav>
        );
    }
  }

  render() {
    return (
      <div>
        <Navbar color="faded" light expand="sm">
          <Container className="justify-content-center">
            <NavbarBrand tag={RRNavLink} to="/" className="mx-auto">
              <b>+++</b>
            </NavbarBrand>

            <NavbarToggler
              onClick={this.toggle.bind(this)}
              className="mx-auto"
            />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto align-items-center" navbar>
                {this.renderContent()}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

// function mapStateToProps({ auth }) {
//   return { auth };
// }

// export default connect(mapStateToProps)(Header);

export default Header;

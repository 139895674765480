import React, { Component, useState } from "react";
import { Row, Col, Card, Button, Badge, Spinner } from "reactstrap";
import SocialIcons from "./SocialIcons";
import EmailListForm from "./EmailListForm";
import axios from "axios";
import useSWR from "swr";

const fetcher = (url) => fetch(url).then((res) => res.json());

function Landing() {
  const { data, error } = useSWR(`/api/info/bogie`, fetcher);
  const [showForm, setShowForm] = useState(false);

  if (error) return <div>failed to load</div>;
  if (!data)
    return (
      <Card className="rounded-more loading-card border text-center text-dark p-5">
        <Spinner className="mx-auto" color="secondary" />
      </Card>
    );

  const image_url =
    "https://res.cloudinary.com/hzziwrslc/image/upload/v1549495681/IMG_0522_eco.jpg";

  const { upcomingRelease, currentRelease } = data;

  return (
    <div style={{ textAlign: "center" }}>
      <Row>
        <Col md={12}>
          <Card
            body
            style={{
              backgroundColor: "rgba(0,0,0,0)",
              marginTop: "-8px",
              borderRadius: "20px",
              border: "0px",
              opacity: "0.7",
              fontFamily: "'Montserrat', sans-serif",
            }}
          >
            <h3 style={{ lineHeight: "0.6", color: "rgba(0,0,0,0.9)" }}>
              {upcomingRelease.name}
            </h3>
            <div
              style={{
                lineHeight: "0.6",
                color: "rgba(0,0,0,0.9)",
                fontSize: "12px",
              }}
            >
              {upcomingRelease.subtitle}
            </div>
          </Card>
          <div
            // className="bg-image-custom"
            // style={{
            //   borderRadius: "20px",
            //   opacity: "0.8",
            //   backgroundImage: `url(${image_url})`,
            // }}
              className="card py-5 rounded-more"
          >
              <Col sm={{ size: 8, offset: 2 }}>
                <div
                  // className="bg-image-custom"
                  // style={{
                  //   borderRadius: "20px"
                  // }}
                >
                  <div className="embed-responsive embed-responsive-16by9">
                  {/*<div className="embed-responsive embed-responsive-21by9">*/}
                    <iframe
                      title="ytPlayer"
                      frameborder='0'
                      className="embed-responsive-item"
                      src="https://www.youtube.com/embed/DtMD7236fLI"
                      frameBorder="0"
                      allow='autoplay; encrypted-media'
                      allowFullScreen
                    />
                  </div>
                </div>
              </Col>


              {/*temporarily off*/}
            <div
              style={{
                fontSize: "11px",
                opacity: "0.5",
                marginTop: "-20px",
                // marginBottom: "4px"
              }}
            >
              LATEST RELEASE
            </div>
            {/*<h3 style={{ lineHeight: "0.6", marginBottom: "4px" }}>*/}
            {/*  {this.state.currentName}*/}
            {/*</h3>*/}

            {/*<Col sm={{ size: 8, offset: 2 }} className="mt-4">*/}
            {/*  <iframe*/}
            {/*    className="embed-responsive-item"*/}
            {/*    title="spotFun"*/}
            {/*    src={currentRelease.spotifyUrl}*/}
            {/*    width="300"*/}
            {/*    height="80"*/}
            {/*    frameBorder="0"*/}
            {/*    allow="encrypted-media"*/}
            {/*  />*/}
            {/*</Col>*/}
            <div
              style={{
                opacity: "0.9",
                fontSize: "24px",
                margin: "0px 0px 6px 0px",
              }}
              className="text-black-50 mt-4"
            >
              {currentRelease.name}
              {/*<Badge color="warning">{this.state.currentName}</Badge>*/}
              {/*<Badge color="warning" style={{marginLeft: "6px"}}> IN {-moment({hours: 0}).diff('2019-03-07', 'days')} DAYS</Badge>*/}
            </div>

            {showForm ? (
              <Col md={{ size: 6, offset: 3 }}>
                <div style={{ marginTop: "80px" }}>
                  <EmailListForm />
                </div>
              </Col>
            ) : (
              // <div style={{ marginTop: "80px" }}>
              <div>
                <Button
                  className="box-shadow"
                  style={{ paddingTop: "8px" }}
                  onClick={() => setShowForm(true)}
                >
                  SUBSCRIBE FOR UPDATES
                </Button>
              </div>
            )}

          </div>
        </Col>
      </Row>

      {/*<Row>*/}
      {/*  <Col sm={{ size: 8, offset: 2 }}>*/}
      {/*    <SocialIcons />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </div>
  );
}

export default Landing;

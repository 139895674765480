import React, { Component } from "react";
import { Row, Col, Input, Button, Alert } from "reactstrap";

class TimerInput extends Component {
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <h3>MEDITATE</h3>
        <div style={{ opacity: "0.8", fontSize: "12px" }}>
          you can adjust the amount of time below
        </div>
        <Input
          className="text-center"
          style={{ maxWidth: "100px", margin: "auto" }}
          type="number"
          value={this.props.value}
          onChange={this.props.handleChange}
          min="1"
          max="90"
          required
        />
      </div>
    );
  }
}

class Timer extends Component {
  render() {
    return (
      <div>
        <h1 style={{ fontSize: 100 }}>
          {this.props.value}:{this.props.seconds}
        </h1>
      </div>
    );
  }
}

class StartButton extends Component {
  render() {
    return (
      <div>
        <Button
          size="lg"
          color="success"
          disabled={!this.props.value}
          onClick={this.props.startCountDown}
        >
          Begin Meditation
        </Button>
      </div>
    );
  }
}

class Meditate extends Component {
  secondsRemaining;
  intervalHandle;

  state = {
    seconds: "00",
    value: "2",
    isClicked: false,
    visible: false
  };

  handleChange = e => {
    this.setState({
      value: e.target.value
    });
  };

  tick = () => {
    var min = Math.floor(this.secondsRemaining / 60);
    var sec = this.secondsRemaining - min * 60;

    this.setState({
      value: min,
      seconds: sec
    });

    if (sec < 10) {
      this.setState({
        seconds: "0" + this.state.seconds
      });
    }

    if ((min === 0) & (sec === 0)) {
      clearInterval(this.intervalHandle);
      this.setState({ visible: true });
    }

    this.secondsRemaining--;
  };

  startCountDown = () => {
    this.intervalHandle = setInterval(this.tick, 1000);
    let time = this.state.value;
    this.secondsRemaining = time * 60;
    this.setState({
      isClicked: true
    });
  };

  resetTimer = () => {
    clearInterval(this.intervalHandle);
    this.setState({
      seconds: "00",
      value: "2",
      isClicked: false,
      visible: false
    });
  };

  handleDismiss = () => {
    this.setState({ visible: false });
  };

  render() {
    const clicked = this.state.isClicked;
    const image =
      "https://res.cloudinary.com/hzziwrslc/image/upload/v1549328613/photo-1482569020076-99150e41169b.jpg";

    if (clicked) {
      return (
        <div style={{ textAlign: "center" }}>
          <Row>
            <Col md={2} />
            <Col md={8}>
              <div
                className="bg-image-custom"
                style={{
                  backgroundImage: `url(${image})`,
                  padding: "30px",
                  borderRadius: "20px"
                }}
              >
                <Alert
                  color="light"
                  style={{
                    opacity: "0.8",
                    marginTop: "-25px",
                    borderRadius: "20px"
                  }}
                  isOpen={this.state.visible}
                  toggle={this.handleDismiss}
                >
                  You are awesome... now take the awareness from the moment
                  and apply it throughout your day.
                </Alert>

                <Timer value={this.state.value} seconds={this.state.seconds} />
                <Button onClick={this.resetTimer}>Reset</Button>
                <div>+++</div>
                <div style={{ marginTop: "30px" }}>GOODNESS EXISTS</div>
                <div>WITHIN YOU</div>
                <div
                  className="bg-image-custom"
                  style={{
                    maxHeight: "200px"
                  }}
                />
              </div>
            </Col>
            <Col md={2}>YOU ARE</Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <Row>
            <Col md={2} />
            <Col md={8}>
              <div
                className="bg-image-custom"
                style={{
                  backgroundImage: `url(${image})`,
                  borderRadius: "20px"
                }}
              >
                <TimerInput
                  value={this.state.value}
                  handleChange={this.handleChange}
                />
                <Timer value={this.state.value} seconds={this.state.seconds} />
                <StartButton
                  startCountDown={this.startCountDown}
                  value={this.state.value}
                />
              </div>
            </Col>
            <Col md={2}>LOVING KINDNESS</Col>
          </Row>
        </div>
      );
    }
  }
}

export default Meditate;

// ---OLD CODE--- //
// if (min < 10) {
//   this.setState({
//     value: "0" + min
//   });
// }

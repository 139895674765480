import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Input, Button, InputGroup, InputGroupAddon } from "reactstrap";
import axios from "axios";
import _ from "lodash";

const EmailListForm = () => (
  <div className="email-list-box">
    <Formik
      initialValues={{ email: "", name: "", msg: "" }}
      validate={(values) => {
        let errors = {};
        if (!values.email) {
          errors.email = "please enter a valid e-mail 😊";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address my dear 😊";
        } else if (!values.name || values.name.length < 3) {
          errors.email = "please enter your lovely name (atleast 3 letters) 😊";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm, setValues }) => {
        setTimeout(() => {
          const formattedName = _.startCase(values.name.trim().toLowerCase());
          const body = { email: values.email, name: formattedName };

          axios
            .post(`/api/email-list`, body)
            .then((res) => {
              // console.log("not sure if it worked but we'll see " + res.data);

              resetForm();
              setValues({
                msg:
                  "Thank you, " +
                  res.data.name +
                  ", may you be blessed with many hugs today 🙏",
              });
              setSubmitting(true);
            })
            .catch(function (error) {
              console.log(error);
              setSubmitting(false);
            });

          // setSubmitting(false);
        }, 400);
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          {values.msg === "" ? (
            <div>
              <h6 style={{ fontSize: "12px" }}>
                Get updates about upcoming shows and releases
              </h6>
              <InputGroup>
                <Input
                  // size="sm"
                  tag={Field}
                  type="text"
                  name="name"
                  placeholder="name"
                />
                <Input
                  // size="sm"
                  tag={Field}
                  type="email"
                  name="email"
                  placeholder="e-mail"
                />
                <InputGroupAddon addonType="append">
                  <Button
                    // size="sm"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Subscribe
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
          ) : (
            <div>{values.msg}</div>
          )}
          {/*{values.msg === "" ? <div>joey</div> : <div>pogos</div>}*/}
          <ErrorMessage name="email" component="div" />
        </Form>
      )}
    </Formik>
  </div>
);

export default EmailListForm;

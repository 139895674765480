import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

const LyricsModal = (props) => {
  const { buttonLabel, className, songTitle, lyrics } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button color="light" className="hover-btn" onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal
        isOpen={modal}
        // centered={true}
        // fade={false}
        toggle={toggle}
        className={className}
        scrollable={true}
        modalTransition={{ timeout: 100 }}
        backdropTransition={{ timeout: 40 }}
      >
        <h4 onClick={toggle} className="border bg-light rounded pb-2">
          <div
            style={{
              right: "0",
              paddingRight: "14px",
              paddingTop: "10px",
              position: "absolute",
              cursor: "pointer",
            }}
          >
            x
          </div>
          <div className="pt-3 mb-0">{songTitle.toUpperCase()}</div>
        </h4>
        <ModalBody className="pb-5">
          {parse(DOMPurify.sanitize(lyrics))}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LyricsModal;

import React, { useState } from "react";
import { DefaultEditor } from "react-simple-wysiwyg";
import { useForm, Controller } from "react-hook-form";
import { Button, Card, Form } from "reactstrap";
import axios from "axios";
// import dayjs from "dayjs";

const LyricsAddEditForm = (props) => {
  const {
    editLyrics,
    id,
    releaseDate,
    songTitle,
    lyrics,
    trackNumber,
    albumTitle,
    fetchData,
  } = props;

  const preloadedValues = !!editLyrics
    ? {
        songTitle: songTitle,
        lyrics: lyrics,
        releaseDate: releaseDate ? releaseDate.toString().substring(0, 10) : "",
        albumTitle: albumTitle,
        trackNumber: trackNumber,
      }
    : {
        songTitle: "",
        releaseDate: "",
        trackNumber: "",
        albumTitle: "",
        lyrics: "",
      };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    formState,
    control,
  } = useForm({
    defaultValues: preloadedValues,
  });

  const [response, setResponse] = useState(null);

  const onSubmit = async (data) => {
    // delete data.id;
    // console.log(data);

    const man = await axios
      .post(`/api/lyrics`, data)
      .then((res) => {
        console.log("data was added " + JSON.stringify(res.data));
        setResponse(res.data);
        return res.data;
      })
      .catch((err) => {
        setResponse({ err: err, msgFromEm: "error in post =/" });
        return err;
      });

    if (man.success) {
      console.log("hugs: " + man.success);
      setResponse(man);
      reset("", {
        keepValues: false,
      });
    }
  };

  const onSubmitEdit = async (data) => {
    // delete data.addNavItem;
    const editId = id;
    console.log("onSubmitEdit: " + JSON.stringify(data));
    console.log("id: " + editId);

    const man = await axios
      .put(`/api/lyrics/${id}`, data)
      .then((res) => {
        console.log("data was updated " + JSON.stringify(res.data));
        setResponse(res.data);
        fetchData();
        return res.data;
      })
      .catch((err) => {
        setResponse({ err: err, msgFromEm: "error in post =/" });
        return err;
      });

    if (man.success) {
      console.log("hugs: " + man.success);
      setResponse(man);
      // fetchData();
      // reset("", {
      //   keepValues: false,
      // });
    }
  };

  return (
    <div>
      <Form
        autoComplete="off"
        onSubmit={
          !!editLyrics ? handleSubmit(onSubmitEdit) : handleSubmit(onSubmit)
        }
      >
        <h4>{!!editLyrics ? "Edit" : "Add"} Lyrics</h4>
        <input
          className="form-control"
          placeholder="song title"
          {...register("songTitle", { required: true })}
        />
        {errors.songTitle && (
          <span className="text-danger">title is required</span>
        )}

        <input
          className="form-control"
          // name="releaseDate"
          type="date"
          {...register("releaseDate")}
        />
        {/*{errors.releaseDate && "release date is required"}*/}

        <input
          className="form-control"
          placeholder="album title"
          {...register("albumTitle")}
        />

        <input
          className="form-control"
          placeholder="track number"
          type="number"
          {...register("trackNumber")}
        />

        {/*<input*/}
        {/*  className="form-control"*/}
        {/*  placeholder="lyrics"*/}
        {/*  {...register('lyrics', { required: true })}*/}
        {/*/>*/}
        {/*{errors.lyrics && "Please enter lyrics"}*/}

        <br />

        {errors.lyrics && (
          <span className="text-danger">please enter lyrics</span>
        )}

        <Controller
          control={control}
          name="lyrics"
          rules={{ required: true }}
          // defaultValue={""}
          render={({ field: { value, onChange } }) => (
            <DefaultEditor
              value={value}
              onChange={onChange}
              className="bg-light text-dark"
            />
          )}
        />

        {!!editLyrics ? (
          <Button
            type="submit"
            // disabled={!formState.dirty || formState.isSubmitting}
            disabled={formState.isSubmitting || !formState.isDirty}
            className="btn btn-light mt-3"
            name="Edit"
          >
            Save Changes
          </Button>
        ) : (
          <Button
            type="submit"
            // disabled={!formState.dirty || formState.isSubmitting}
            disabled={formState.isSubmitting}
            className="btn btn-light mt-3"
            name="Edit"
          >
            Add Lyrics
          </Button>
        )}
      </Form>
      <br />

      {response && (
        <Card
          body
          className="text-dark"
          style={{ overflow: "auto", maxHeight: "300px" }}
        >
          <h3>Response</h3>
          {JSON.stringify(response)}
        </Card>
      )}

      {/*<DefaultEditor value={html} onChange={onChange} />*/}
      {/*<Button onClick={handleClick}>Save Changes</Button>*/}
    </div>
  );
};

export default LyricsAddEditForm;

import React from "react";
import {Row, Col, Card, Button} from "reactstrap";
import SocialIcons from "./SocialIcons";

const Landing = () => {
  const image_url =
    "https://res.cloudinary.com/hzziwrslc/image/upload/v1549495618/IMG_0493_eco.jpg";
  const image_postcard = "//vinbogart.myshopify.com/cdn/shop/files/postcard-4inx6in-hFRONT-FLATTENED.jpg?v=1711395093&width=550 550w,//vinbogart.myshopify.com/cdn/shop/files/postcard-4inx6in-hFRONT-FLATTENED.jpg?v=1711395093&width=1100 1100w,//vinbogart.myshopify.com/cdn/shop/files/postcard-4inx6in-hFRONT-FLATTENED.jpg?v=1711395093&width=1445 1445w,//vinbogart.myshopify.com/cdn/shop/files/postcard-4inx6in-hFRONT-FLATTENED.jpg?v=1711395093&width=1680 1680w,//vinbogart.myshopify.com/cdn/shop/files/postcard-4inx6in-hFRONT-FLATTENED.jpg?v=1711395093&width=2048 2048w,//vinbogart.myshopify.com/cdn/shop/files/postcard-4inx6in-hFRONT-FLATTENED.jpg?v=1711395093 2135w";
  // const buy_button_url = "https://vinbogart.myshopify.com/products/lovely-postcard";
  const buy_button_url = "https://vinbogart.myshopify.com/";

  return (
    <div
      style={{
        textAlign: "center",
        opacity: "0.8",
        backgroundSize: "cover",
        height: "500px",
        backgroundImage: `url(${image_url})`,
        backgroundPosition: "center",
        paddingTop: "30px",
        borderRadius: "20px",
      }}
    >
      <Row>
        <Col md={4} />
        <Col md={4}>
          <div
            className="bg-image-custom"
            // style={{
            //   borderRadius: "20px",
            //   opacity: "0.9",
            //   backgroundImage: `url(${image_url})`,
            // }}
          >
            <h4>FIND YOUR KINDNESS</h4>
            {/*<h6>+++</h6>*/}

            <Card
              style={{
                margin: "20px",
                backgroundColor: "rgba(0,0,0,0.9)",
                borderRadius: "20px",
              }}
              body
            >
              {/*Vin Bogart kindness gear will be available soon...*/}
              {/*<p />*/}
              {/*<div>*/}
              {/*  Yes. We call it kindness gear. Because if you wear it, and still*/}
              {/*  act like an asshole, Bogart will find you. And gently slap the*/}
              {/*  unkindness out of you...*/}
              {/*</div>*/}
              {/*<p />*/}
              {/*<div>*/}
              {/*  Then, he will give you an apologetic hug. Because he acted from*/}
              {/*  his ego. And he still loves you.*/}
              {/*</div>*/}


                    <img src={image_postcard} class="img-fluid" alt="Postcard" />

                <Button href={buy_button_url} tag="a" color="primary">
                    GO TO STORE
                </Button>
            </Card>
          </div>
        </Col>
        <Col md={4} />
      </Row>

      {/*<Row>*/}
      {/*  <Col md={2} />*/}
      {/*  <Col md={8}>*/}
      {/*    <SocialIcons />*/}
      {/*  </Col>*/}
      {/*  <Col md={2} />*/}
      {/*</Row>*/}
    </div>
  );
};

export default Landing;

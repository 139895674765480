import React from "react";
import { Card } from "reactstrap";
import { SocialIcon } from "react-social-icons";

const SocialIcons = () => {
  return (
    <Card  style={{ marginTop: "20px", padding: "5px", opacity: "0.6", borderRadius: "20px"}}>
      <div style={{ alignSelf: "center" }}>
        <SocialIcon bgColor="#6C6C6C" url="http://instagram.com/vinbogart" />
        <SocialIcon bgColor="#6C6C6C" url="http://youtube.com/vinbogart" />
        {/*<SocialIcon bgColor="#6C6C6C" url="http://soundcloud.com/vinbogart" />*/}
        {/*<SocialIcon bgColor="#6C6C6C" url="http://twitter.com/vinbogart" />*/}
        {/*<SocialIcon bgColor="#6C6C6C" url="http://facebook.com/vinbogart" />*/}
        <SocialIcon bgColor="#6C6C6C" url="https://open.spotify.com/artist/6VygtAEqzazNqiOd0K6dXT?si=FIl25MSRTwWaxmmsGKNTgA" />
      </div>
    </Card>
  );
};

export default SocialIcons;

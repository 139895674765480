import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
// import { connect } from "react-redux";
// import * as actions from "../actions";

import Header from "./Header";
// import LandingOG from "./LandingOG";
import UpdateInfo from "./UpdateInfo";
import Shop from "./Shop";

import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import Film from "./Film";
import Music from "./Music";
import Meditate from "./Meditate";
import SpreadLove from "./SpreadLove";
import LyricsAdmin from "./Lyrics/LyricsAdmin";
import {Col, Container, Row} from 'reactstrap';
import Landing from "./Landing";
import SocialIcons from "./SocialIcons";
import Lyrics from "./Lyrics/Lyrics";
import Films from "./Films/Films";

class App extends Component {
  // componentDidMount() {
  //   this.fetchInfo();
  // }
  //
  // fetchInfo = id => {
  //   axios
  //     .get(`/api/info/${id}`)
  //     .then(res => {
  //       const { upcomingRelease, currentRelease } = res.data;
  //       this.setState({
  //         upcomingName: upcomingRelease.name,
  //         upcomingSubtitle: upcomingRelease.subtitle,
  //         currentName: currentRelease.name,
  //         currentSubtitle: currentRelease.subtitle,
  //         currentSpotifyUrl: currentRelease.spotifyUrl
  //       })
  //     })
  //     .catch(function(error) {
  //       // handle error
  //       console.log(error);
  //     });
  // };

  render() {
    return (
      <BrowserRouter>
        <Container>
          <Header />
          {/*<Route exact path="/" render={() => (<Redirect to="/music"/>)} />*/}
          <Route exact path="/" component={Landing} />
          <Route exact path="/music" component={Music} />
          <Route exact path="/shop" component={Shop} />
          <Route exact path="/film" component={Film} />
          <Route exact path="/meditate" component={Meditate} />
          <Route exact path="/spread-love" component={SpreadLove} />
          <Route exact path="/lyrics" component={Lyrics} />
          <Route exact path="/lyrics/admin"    component={() => <Lyrics showDetails={true} showDelete={true}/>}/>
          <Route exact path="/lyrics/add" component={LyricsAdmin} />
          <Route exact path="/update/clown/info" component={UpdateInfo} />
          <Route exact path="/films" component={Films} />

          {/*<Route exact path="/*" render={() => (<Redirect to="/music"/>)} />*/}



          {/*<Route exact path="/dash" component={Dashboard} />*/}
          <Row className="footer-em">
            <Col md={2} />
            <Col md={8}>
              <SocialIcons />
            </Col>
            <Col md={2} />
          </Row>
        </Container>

      </BrowserRouter>
    );
  }
}

// export default connect(
//   null,
//   actions
// )(App);

export default App;
import React from "react";
import { Row, Col } from "reactstrap";
import SocialIcons from "./SocialIcons";

const Music = () => {
  const image_url =
    "https://res.cloudinary.com/hzziwrslc/image/upload/v1549271828/background1smaller.jpg";
  return (
    <div
      style={{
        textAlign: "center",
        opacity: "0.8",
        backgroundSize: "cover",
        height: "500px",
        backgroundImage: `url(${image_url})`,
        backgroundPosition: "center",
        paddingTop: "30px",
        borderRadius: "20px"
      }}
    >
      <Row>
        <Col style={{opacity: "0.6", fontSize: "12px"}} md={3}>LOVING</Col>
        <Col md={6}>
          <h6>VIN BOGART</h6>
          <h1 style={{ fontFamily: "'Montserrat', sans-serif" }}>THE WAY</h1>

          <iframe
            title="spotPlayer"
            src="https://open.spotify.com/embed/artist/6VygtAEqzazNqiOd0K6dXT"
            width="300"
            height="340"
            frameBorder="0"
            allow="encrypted-media"
          />
        </Col>
        <Col style={{opacity: "0.6", fontSize: "12px"}} md={3}>KINDNESS</Col>
      </Row>

      {/*<Row>*/}
      {/*  <Col md={3} />*/}
      {/*  <Col md={6}>*/}
      {/*    <SocialIcons />*/}
      {/*  </Col>*/}
      {/*  <Col md={3} />*/}
      {/*</Row>*/}
    </div>
  );
};

export default Music;

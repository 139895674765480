import React from "react";
import { Row, Col, Card } from "reactstrap";
import SocialIcons from "./SocialIcons";

const SpreadLove = () => {
  const imageBw =
    "https://res.cloudinary.com/hzziwrslc/image/upload/e_saturation:-60/v1549329534/photo-1518568814500-bf0f8d125f46.jpg";

  return (
    <div style={{ textAlign: "center" }}>
      <Row>
        <Col md={2} />
        <Col md={8}>
          <div
            className="bg-image-custom"
            style={{
              backgroundImage: `url(${imageBw})`,
              padding: "15px 60px",
              borderRadius: "20px"
            }}
          >
            <Card body style={{ backgroundColor: "rgba(0,0,0,0.6)", borderRadius: "20px" }}>
              <h3>SPREAD LOVE</h3>
              <span>
                Discover simple ways you can spread love in your home,
                community, the world... and within yourself.
              </span>
            </Card>
            <p />
            <Card body style={{ backgroundColor: "rgba(0,0,0,0.1)", borderRadius: "20px" }}>
               <b>KINDNESS MANIFESTO</b> will be available soon.
            </Card>
          </div>

          {/*<SocialIcons />*/}
        </Col>
        <Col md={2} />
      </Row>
    </div>
  );
};

export default SpreadLove;

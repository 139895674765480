import React from "react";
import { Row, Col, Card, Form } from "reactstrap";
import LyricsAddEditForm from "./LyricsAddEditForm";

const LyricsAdmin = () => {
  const image_url =
    "https://res.cloudinary.com/hzziwrslc/image/upload/v1549271828/background1smaller.jpg";

  return (
    <div>
      <Row className="text-center">
        <Col>
          <h6>---</h6>
          <Card className="bg-dark" body>
            <LyricsAddEditForm />

          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LyricsAdmin;

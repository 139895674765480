import React from "react";
import { Row, Col } from "reactstrap";
import SocialIcons from "./SocialIcons";

const Film = () => {
  // const image_url =
  //   "https://res.cloudinary.com/hzziwrslc/image/upload/v1549495681/IMG_0522_eco.jpg";
  return (
    <div style={{ textAlign: "center", color: "black" }}>
      <Row>
        <Col sm={{ size: 8, offset: 2 }} className="pt-4">
          COMING 2025
        </Col>

        {/*<Col sm={{ size: 8, offset: 2 }}>*/}
        {/*  <div*/}
        {/*    // className="bg-image-custom"*/}
        {/*    style={{*/}
        {/*      borderRadius: "20px"*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <h3 style={{ marginBottom: "-12px" }}>SMILE</h3>*/}

        {/*    <div>+++</div>*/}
        {/*    <p />*/}
        {/*    <div className="embed-responsive embed-responsive-21by9">*/}
        {/*      <iframe*/}
        {/*        title="ytPlayer"*/}
        {/*        className="embed-responsive-item"*/}
        {/*        src="https://www.youtube.com/embed/p9zUh2OkVBE"*/}
        {/*        frameBorder="0"*/}
        {/*        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
        {/*        allowFullScreen*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Col>*/}
        {/*<Col sm={{ size: 8, offset: 2 }}>*/}
        {/*  <div*/}

        {/*  >*/}
        {/*    /!*<h3 style={{ lineHeight: "0.6" }}>BELONG</h3>*!/*/}
        {/*    /!*<div>+++</div>*!/*/}
        {/*    <p />*/}
        {/*    <div className="embed-responsive embed-responsive-21by9">*/}
        {/*      <iframe*/}
        {/*        title="ytPlayer"*/}
        {/*        className="embed-responsive-item"*/}
        {/*        // width="300"*/}
        {/*        // height="200"*/}
        {/*        src="https://www.youtube.com/embed/QfMYxJ10wkQ"*/}
        {/*        frameBorder="0"*/}
        {/*        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
        {/*        allowFullScreen*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Col>*/}
      </Row>

      {/*<Row>*/}
      {/*  <Col sm={{ size: 8, offset: 2 }}>*/}
      {/*    <SocialIcons />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </div>
  );
};

export default Film;

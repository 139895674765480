import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import LyricsAddEditForm from "./LyricsAddEditForm";

const LyricsEdit = (props) => {
  const {
    buttonLabel,
    classNameProp,
    songTitle,
    lyrics,
    releaseDate,
    albumTitle,
    trackNumber,
    id,
    fetchData,
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button color="primary" block className="hover-btn" onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal
        isOpen={modal}
        fade={false}
        toggle={toggle}
        className={classNameProp}
        scrollable={true}
      >
        <h4 onClick={toggle} className="border bg-light rounded pb-2">
          <div className="pt-3 mb-0">Edit {songTitle}</div>
        </h4>
        <ModalBody className="pb-5">
          <LyricsAddEditForm
            editLyrics={true}
            id={id}
            releaseDate={releaseDate}
            songTitle={songTitle}
            lyrics={lyrics}
            albumTitle={albumTitle}
            trackNumber={trackNumber}
            fetchData={fetchData}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LyricsEdit;

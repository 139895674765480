import React, { Component } from "react";
import { Button, Form, FormGroup, Input, Card, Col, Row } from "reactstrap";
import axios from 'axios';

class UpdateInfo extends Component {
  state = {
    username: "",
    secret: "",
    upcomingName: "",
    upcomingSubtitle: "",
    currentName: "",
    currentSubtitle: "",
    currentSpotifyUrl: "",
    showInfoForm: "",
    error: ""
  };

  componentDidMount() {
    // console.log("we mounted");
  }

  clearCourse = () => {
    document.getElementById("login-course").reset();
    // this.setState({ codeLocal: "" });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  // addNewInfo = () => {
  //   console.log("great");
  //   axios
  //     .post("/api/info")
  //     .then(function(response) {
  //       console.log(response);
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // };

  updateInfo = name => {
    const body = {
      upcomingName: this.state.upcomingName,
      upcomingSubtitle: this.state.upcomingSubtitle,
      currentName: this.state.currentName,
      currentSubtitle: this.state.currentSubtitle,
      currentSpotifyUrl: this.state.currentSpotifyUrl
    };

    axios
      .put(`/api/info/${name}`, body)
      .then(res => {
        // console.log(res);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  fetchInfo = name => {
    axios
      .get(`/api/info/${name}`)
      .then(res => {
        const { upcomingRelease,currentRelease } = res.data;

        // console.log("FETCHED INFOOOOO " + upcomingRelease.name);
        // console.log(res);

        this.setState({
          upcomingName: upcomingRelease.name,
          upcomingSubtitle: upcomingRelease.subtitle,
          currentName: currentRelease.name,
          currentSubtitle: currentRelease.subtitle,
          currentSpotifyUrl: currentRelease.spotifyUrl
        })

      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  };

  handleSubmit = e => {
    e.preventDefault();

    if (
      this.state.secret === "bogiesgotlove" &&
      this.state.username === "bogart"
    ) {
      this.fetchInfo(
        "bogie",
        this.setState({ showInfoForm: "lifeisgood1", error: "" })
      );
    } else {
      this.setState({ error: "incorrect info my lovely friend" });
    }
    this.clearCourse();
  };

  handleUpdateInfo = e => {
    e.preventDefault();
  };

  render() {
    return (
      <div>
        <Card body>
          {this.state.error && (
            <div style={{ color: "red" }}>{this.state.error}</div>
          )}
          {this.state.showInfoForm === "" && (
            <Form
              onSubmit={this.handleSubmit}
              id="login-course"
              autoComplete="off"
            >
              <FormGroup>
                <Row>
                  <Col xs="4">
                    <Input
                      type="text"
                      name="username"
                      id="username"
                      placeholder="username"
                      value={this.state.username}
                      onChange={this.handleChange}
                      required
                    />
                  </Col>
                  <Col xs="4">
                    <Input
                      type="password"
                      name="secret"
                      id="secret"
                      placeholder="enter secret"
                      onChange={this.handleChange}
                      required
                    />
                  </Col>
                </Row>
                <Button color="dark" style={{ marginTop: "2rem" }}>
                  Open
                </Button>
              </FormGroup>
            </Form>
          )}

          {this.state.showInfoForm === "lifeisgood1" && (
            <div>
              <Form
                id="update-course"
                autoComplete="off"
                onSubmit={this.handleUpdateInfo}
              >
                <FormGroup>
                  <Row>
                    <Col xs="4">
                      <Input
                        type="text"
                        name="upcomingName"
                        id="upcomingName"
                        placeholder="upcoming song name"
                        value={this.state.upcomingName}
                        onChange={this.handleChange}
                        required
                      />
                    </Col>
                    <Col xs="4">
                      <Input
                        type="text"
                        name="upcomingSubtitle"
                        id="upcomingSubtitle"
                        placeholder="enter secret"
                        value={this.state.upcomingSubtitle}
                        onChange={this.handleChange}
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4">
                      <Input
                        type="text"
                        name="currentName"
                        id="currentName"
                        placeholder="current song name"
                        value={this.state.currentName}
                        onChange={this.handleChange}
                        required
                      />
                    </Col>
                    <Col xs="4">
                      <Input
                        type="text"
                        name="currentSubtitle"
                        id="currentSubtitle"
                        placeholder="current song subtitle"
                        value={this.state.currentSubtitle}
                        onChange={this.handleChange}
                        required
                      />
                    </Col>

                  </Row>
                  <Row>
                    <Col xs="8">
                      <Input
                        type="text"
                        name="currentSpotifyUrl"
                        id="currentSpotifyUrl"
                        placeholder="enter spotify url"
                        value={this.state.currentSpotifyUrl}
                        onChange={this.handleChange}
                        required
                      />
                    </Col>
                  </Row>
                  <Button
                    style={{ marginTop: "2rem" }}
                    onClick={() => this.updateInfo("bogie")}
                  >
                    {" "}
                    Update Info
                  </Button>
                </FormGroup>
              </Form>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export default UpdateInfo;

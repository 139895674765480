import React, { useState } from "react";
import { Row, Col, Card, Button, Spinner } from "reactstrap";
import useSWR from "swr";

const fetcher = (url) => fetch(url).then((res) => res.json());

function useFilms(title) {
    const { data, error } = useSWR(`/api/films/${title}`, fetcher);

    return {
        data: data,
        isLoading: !error && !data,
        error: error,
    };
}

function Film({filmTitle}) {
    // const { data, error } = useSWR(`/api/films/${title}`, fetcher);

    const { data, isLoading, error } = useFilms(filmTitle);

    if (error)
        return (
            <div className="rounded-more border text-center text-dark px-2 pt-4 pb-5 mb-4">
                failed to load
            </div>
        );
    if (isLoading)
        return (
            <Card className="rounded-more loading-card border text-center text-dark p-5">
                <Spinner className="mx-auto" color="secondary" />
            </Card>
        );

    // const { upcomingRelease, currentRelease } = data;

    return (
        <div className="rounded-more border text-center text-dark px-2 pt-4 pb-5 mb-4">
            <Row>
                <Col md={12}>
                    <Card
                        body
                        style={{
                            backgroundColor: "rgba(0,0,0,0)",
                            marginTop: "-8px",
                            borderRadius: "20px",
                            border: "0px",
                            opacity: "0.7",
                            fontFamily: "'Montserrat', sans-serif",
                        }}
                    >
                        <h3 style={{ lineHeight: "0.6", color: "rgba(0,0,0,0.9)" }}>
                            {JSON.stringify(data)}
                        </h3>
                        {/*<div*/}
                        {/*  style={{*/}
                        {/*    lineHeight: "0.6",*/}
                        {/*    color: "rgba(0,0,0,0.9)",*/}
                        {/*    fontSize: "12px",*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {upcomingRelease.subtitle}*/}
                        {/*</div>*/}
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Film;
